import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Bu sayfa için yetkiniz bulunmamaktadır."
      extra={<Link to="/">Anasayfa'ya dön</Link>}
    />
  )
}

export default Unauthorized;