import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { useAuth } from "./hooks/useAuth";
import AuthLayout from "./containers/AuthLayout";
import SignIn from "./routes/Auth/SignIn";
import ForgotPassword from "./routes/Auth/ForgotPassword";
import ResetPassword from "./routes/Auth/ResetPassword";

import DashboardLayout from "./containers/DashboardLayout";
import DashboardRoutes from './routes';

import "./styles/main.less";
import "moment/locale/tr";
import localeTr from "antd/lib/locale/tr_TR";
import { ConfigProvider } from 'antd';

const App = () => {
  const { claims } = useAuth();

  return (
    <ConfigProvider locale={localeTr}>
      <Routes>

        <Route element={<AuthLayout />}>
          <Route path='/signin' element={<SignIn />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password/:resetCode' element={<ResetPassword />} />
        </Route>

        <Route element={<DashboardLayout />}>
          {DashboardRoutes(claims.isSuperAdmin).map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Route>

      </Routes>
    </ConfigProvider>
  )
}

export default App;
