import React, { useEffect, useState } from "react";
// import DomainTableFilter from "./DomainTableFilter";
import DomainAddForm from "./DomainAddForm";
import DomainUpdateForm from "./DomainUpdateForm";

import {
  Popconfirm,
  Col,
  Row,
  Button,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { PageHeader, Table, Drawer } from "../../components/AntdCustom";
import { useApi } from "../../hooks/useApi";

const Domain = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: false,
  });
  const [tableLoadOptions, setTableLoadOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
  });
  const [addFormVisible, setAddFormVisible] = useState(false);
  const [updateForm, setUpdateForm] = useState({ data: {}, loading: false, visible: false });

  const { getRequestWithPagingQuery, getRequest, deleteRequest } = useApi();

  useEffect(() => {
    getTableData();
  }, [tableLoadOptions, props.tenantId]);

  const getTableData = async () => {
    if (!props.tenantId) return;

    setTableData({ ...tableData, loading: true });
    tableLoadOptions.filter = ["tenantId", "=", props.tenantId]
    const response = await getRequestWithPagingQuery("/DomainAddresses", tableLoadOptions);

    if (!response.isSuccess) {
      return setTableData({ ...tableData, loading: false });
    }

    setTableData({
      data: response.data.data,
      total: response.data.totalCount,
      loading: false,
    });
  };

  const paging = (p) => {
    setTableLoadOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const handleOnClickUpdate = async (id) => {
    setUpdateForm({ ...updateForm, loading: true, visible: true });
    const response = await getRequest(`/DomainAddresses/${id}`);

    if (!response.isSuccess) {
      return setUpdateForm({ ...updateForm, loading: false, visible: false });
    }

    setUpdateForm({ data: response.data, loading: false, visible: true });
  };

  const handleOnClickDelete = async (id) => {
    const response = await deleteRequest(`/DomainAddresses/${id}`);

    if (response.isSuccess) {
      getTableData();
    }
  };

  const onClearTableFilter = () => {
    setTableLoadOptions({
      ...tableLoadOptions,
      skip: 0,
      current: 1,
    });
  };

  const onFinishTableFilter = (query) => {
    setTableLoadOptions((prevState) => {
      return { ...prevState, skip: 0, current: 1, filter: query };
    });
  };

  const onSuccessAdd = () => {
    getTableData();
    setAddFormVisible(false);
  };

  const onSuccessUpdate = () => {
    getTableData();
    setUpdateForm({ ...updateForm, visible: false, data: {} });
  };

  const columns = [
    {
      title: "Alan Adı Adresi",
      dataIndex: "address",
    },
    {
      title: "Düzenle",
      width: "1%",
      align: "center",
      render: (row) => (
        <Button type="link" onClick={() => handleOnClickUpdate(row.id)} icon={<EditOutlined style={{ color: "orange" }} />} />
      ),
    },
    {
      title: "Sil",
      width: "1%",
      align: "center",
      render: (row) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(row.id)}
        >
          <Button type="link" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Alan Adları"
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAddFormVisible(true)}
          >
            Alan Adı Ekle
          </Button>
        }
      />

      {/* <DomainTableFilter
        tenantId={props.tenantId}
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      /> */}

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            onChange={paging}
            pagination={{
              defaultPageSize: tableLoadOptions.take,
              current: tableLoadOptions.current,
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
          />
        </Col>
      </Row>

      <Drawer
        id="addDomainDrawer"
        title="Alan Adı Ekle"
        onClose={() => setAddFormVisible(false)}
        visible={addFormVisible}
      >
        <DomainAddForm data={{ tenantId: props.tenantId }} onSuccess={onSuccessAdd} />
      </Drawer>

      <Drawer
        id="updateDomainDrawer"
        title="Alan Adı Güncelle"
        onClose={() => setUpdateForm({ data: {}, loading: false, visible: false })}
        visible={updateForm.visible}
        loading={updateForm.loading}
      >
        <DomainUpdateForm
          data={updateForm.data}
          onSuccess={onSuccessUpdate}
        />
      </Drawer>


    </>
  );
};

export default Domain;
