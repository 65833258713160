import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Upload, InputNumber, notification, Switch, Select, TreeSelect } from "antd";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";
import { TextEditor } from "../../components/AntdCustom";
import urlSlug from "url-slug";

const ProductUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [categorySelect, setCategorySelect] = useState({ data: [], loading: false });
  const [imageFileList, setImageFileList] = useState([]);
  const [form] = Form.useForm();
  const { formRequest, getRequest, getFilterQueryString } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);

      setImageFileList(props.data.image ? [{ uid: -1, status: 'done', url: props.data.image }] : []);

    }
  }, [props.data]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async (categoryId) => {
    setCategorySelect({ ...categorySelect, loading: true });

    const query = getFilterQueryString({
      select: ["id", "title", "topCategoryId", "hasSubCategories"],
      sort: [{ selector: "title", desc: false }],
      filter: categoryId ? ["topCategoryId", "=", categoryId] : undefined
    });

    const response = await getRequest(`/ProductCategories${query}`);

    if (!response.isSuccess) {
      return setCategorySelect({ ...categorySelect, loading: false });
    }

    //first load
    if (!categoryId) {
      const defaultData = [{ id: -1, value: -1, title: "Ürünlerimiz", isLeaf: response.data.data.length === 0 }];

      setCategorySelect({
        data: defaultData.concat(convertToTreeData(response.data.data)),
        loading: false,
      });
      return;
    }

    //load category children
    setCategorySelect({
      data: categorySelect.data.concat(convertToTreeData(response.data.data)),
      loading: false,
    });
  };

  const convertToTreeData = (data) => data.map(x => ({ id: x.id, title: x.title, value: x.id, pId: x.topCategoryId ?? -1, isLeaf: !x.hasSubCategories }));

  const onFinish = async (values) => {
    values.urlSlug = values.urlSlug ?? values.title
    values.urlSlug = urlSlug(values.urlSlug, {
      dictionary: { 'ı': 'i', 'I': 'i', },
      transformer: (fragments, separator) => fragments.map(fragment => fragment.toLowerCase()).join(separator)
    });

    values.topCategoryId = values.topCategoryId === -1 ? null : values.topCategoryId;

    const response = await formRequest(`/Products`, "put", values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  const imageUploadProps = {
    fileList: imageFileList,
    maxCount: 1,
    accept: ".png,.jpg,.jpeg",
    listType: "picture-card",
    onRemove: (file) => {
      const index = imageFileList.indexOf(file);
      const newFileList = imageFileList.slice();
      newFileList.splice(index, 1);
      setImageFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"]
      const isAllowed = allowedFileTypes.includes(file.type);

      if (!isAllowed) {
        notification.error({
          message: `'${file.name}' desteklenmeyen dosya tipinde.`,
          duration: 3,
        });
        return Upload.LIST_IGNORE;
      }

      setImageFileList([...fileList]);
      return false;
    },
  };

  return (
    <Form
      layout="vertical"
      validateMessages={defaultValidateMessages}
      form={form}
      name="ProductUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item name="id" hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Başlık"
            name="title"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Sayfa Url"
            name="urlSlug"
            tooltip={
              <>
                <p>'Sayfa Url' girilmezse, başlıktan bir url üretilir.</p>
                <p>Girilen url 'test-urun' ise detay sayfası '/urunlerimiz/kategori-url/test-urun' olur.</p>
              </>
            }
          >
            <Input placeholder="test-urun" />
          </Form.Item>

          <Form.Item
            label="Kategori"
            name="topCategoryId"
            initialValue={-1}
            tooltip="Seçilmez ya da 'Ürünlerimiz' seçilirse, ana kategori olarak ürünlerimiz sayfasında görüntülenir."
          >
            <TreeSelect
              treeDataSimpleMode
              treeDefaultExpandAll
              treeLine={{ showLeafIcon: false }}
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              placeholder="Ürünlerimiz"
              loadData={(treeData) => treeData.children ? Promise.resolve(undefined) : getCategories(treeData.id)}
              treeData={categorySelect.data}
              loading={categorySelect.loading}
            />
          </Form.Item>

          <Form.Item
            label="Görsel"
            name="image"
            getValueFromEvent={({ file }) => file}
            rules={[{ required: true }]}
          >
            <Upload {...imageUploadProps}><Button icon={<UploadOutlined />}>{imageFileList.length === 0 ? "Yükle" : "Değiştir"}</Button></Upload>
          </Form.Item>

          <Form.Item
            label="Yayınla"
            name="published"
            initialValue={true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Sıra"
            name="order"
            initialValue={0}
          >
            <InputNumber precision={0} />
          </Form.Item>

          <Form.Item
            label="Kısa Açıklama"
            name="shortDescription"
            getValueFromEvent={(_, editor) => editor.getData()}
            tooltip="Girilen içerik ürün detay sayfasında, ürün görselinin sağında görünür."
          >
            <TextEditor data={props.data?.shortDescription ? props.data?.shortDescription : undefined} />
          </Form.Item>

          <Form.Item
            label="Açıklama"
            name="description"
            getValueFromEvent={(_, editor) => editor.getData()}
            tooltip="Girilen içerik ürün detay sayfasında, ürün görselinin ve kısa açıklamanın altında görünür."
          >
            <TextEditor data={props.data?.description ? props.data?.description : undefined} />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default ProductUpdateForm;
