import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";

const TenantAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [dealerSelect, setDealerSelect] = useState({
    data: [],
    loading: false,
  });
  const [form] = Form.useForm();
  const { getRequest, postRequest, getFilterQueryString } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onDealerSearch = async (val) => {
    if (val.length < 2) {
      return setDealerSelect({ data: [], loading: false });
    }

    const query = getFilterQueryString({
      select: ["id", "code", "name", "shortName", "address"],
      filter: [["tenantId", "=", null], "and", [["shortName", "contains", val], "or", ["name", "contains", val], "or", ["code", "contains", val]]]
    });
    setDealerSelect({ ...dealerSelect, loading: true });
    const response = await getRequest(`/Dealers${query}`);
    setDealerSelect({ data: response.isSuccess ? response.data.data : [], loading: false });
  };

  const onFinish = async (values) => {
    const response = await postRequest(`/Tenants`, values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Form
      {...defaultFormItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="TenantAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item
            label="Site Başlığı"
            name="siteTitle"
            tooltip="Bayi adı uzun ise girilmelidir. Websitesinde bayi adı yerine kullanılır."
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Bayi"
            name="dealerId"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Aramak için en az 2 harf giriniz.."
              onSearch={onDealerSearch}
              loading={dealerSelect.loading}
              filterOption={(input, option) => option.searchValue.toLocaleLowerCase("tr-TR").indexOf(input.toLocaleLowerCase("tr-TR")) >= 0}
            >
              {dealerSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id} searchValue={`${item.code}-${item.shortName}-${item.name}`}>
                  <>
                    <p><strong>{item.name}</strong></p>
                    <p>{`${item.code} - ${item.shortName}`}<br />{item.address}</p>
                  </>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Kullanıcı Ad" name="userFirstName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Kullanıcı Soyad" name="userLastName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Kullanıcı E-posta"
            name="userEmail"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Kullanıcı Şifre"
            name="userPassword"
            tooltip="Kullanıcı şifresi girilmez ise sistem tarafından şifre üretilip kullanıcıya e-posta yollanır."
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default TenantAddForm;
