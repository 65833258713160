import React from 'react';
import { Layout } from "antd";
import { useOutlet, Navigate } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';

const { Content } = Layout;

const AuthLayout = () => {
  const outlet = useOutlet();
  const { token } = useAuth();

  return token ? (
    <Navigate to="/" />
  ) :
    (
      <Layout className="auth-layout">
        <Content>
          {outlet}
        </Content>
      </Layout>
    );
}

export default AuthLayout;