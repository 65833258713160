import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Collapse,
} from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useApi } from "../../hooks/useApi";

const TenantTableFilter = (props) => {
  const [data, setData] = useState(props.data);
  const [tenantSelect, setTenantSelect] = useState({
    data: [],
    loading: false,
  });

  const [form] = Form.useForm();
  const { getRequest, getFilterQueryString } = useApi();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const onTenantSearch = async (val) => {
    if (val.length < 2) {
      return setTenantSelect({ data: [], loading: false });
    }

    const query = getFilterQueryString({
      select: ["id", "dealerCode", "dealerName", "dealerShortName", "dealerAddress", "siteTitle"],
      filter: [["dealerShortName", "contains", val], "or", ["dealerName", "contains", val], "or", ["dealerCode", "contains", val], "or", ["siteTitle", "contains", val]]
    });
    setTenantSelect({ ...tenantSelect, loading: true });
    const response = await getRequest(`/Tenants${query}`);
    setTenantSelect({ data: response.isSuccess ? response.data.data : [], loading: false });
  };

  const onFinish = (values) => {
    let filter = null;
    const conditions = [];

    if (values.id) {
      conditions.push(["id", "=", values.id]);
    }

    if (values.dealerCode) {
      conditions.push(["dealerCode", "contains", values.dealerCode]);
    }

    if (values.dealerName) {
      conditions.push([["dealerName", "contains", values.dealerName], "or", ["dealerShortName", "contains", values.dealerName]]);
    }

    if (values.siteTitle) {
      conditions.push(["siteTitle", "contains", values.siteTitle]);
    }

    if (conditions.length !== 0) {
      filter = [];

      conditions.forEach((condition, i) => {
        filter.push(condition);
        const isLastIndex = conditions.length === i + 1;
        if (!isLastIndex) filter.push("and");
      });

    }

    if (props.onFinishFilter) props.onFinishFilter(filter);

  };

  const clearFilter = () => {
    form.resetFields();
    setTenantSelect({ ...tenantSelect, data: [] });

    if (props.onClearFilter) {
      props.onClearFilter();
    }
  };

  return (
    <Row id="tenantFilterArea" style={{ ...props.style }}>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel header="Filtreleme için tıklayınız.">
            <Form
              name="tenantFilter"
              form={form}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row style={{ flexDirection: "row" }}>

                <Col span={6}>
                  <Form.Item name="id" label="Bayi :">
                    <Select
                      allowClear
                      showSearch
                      placeholder="En az 2 harf giriniz.."
                      onSearch={onTenantSearch}
                      loading={tenantSelect.loading}
                      filterOption={(input, option) => option.searchValue.toLocaleLowerCase("tr-TR").indexOf(input.toLocaleLowerCase("tr-TR")) >= 0}
                    >
                      {tenantSelect.data.map((item) => (
                        <Select.Option key={item.id} value={item.id} searchValue={`${item.dealerCode}-${item.dealerShortName}-${item.dealerName}-${item.siteTitle}`}>
                          <>
                            <p><strong>{item.dealerName}</strong></p>
                            <p>{`${item.dealerCode} - ${item.dealerShortName}`}<br />{item.dealerAddress}</p>
                          </>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item name="dealerCode" label="Bayi Kodu :">
                    <Input allowClear />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item name="dealerName" label="Bayi Adı :">
                    <Input allowClear />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item name="siteTitle" label="Site Başlığı :">
                    <Input allowClear />
                  </Form.Item>
                </Col>

              </Row>

              <Row style={{ flexDirection: "row" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() => clearFilter()}
                  >
                    Sıfırla
                  </Button>
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >
                    Filtrele
                  </Button>
                </Col>
              </Row>

            </Form>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
export default TenantTableFilter;
