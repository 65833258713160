import React, { useState } from 'react';
import { Button, Form, Row, Col, Input, Card, Checkbox } from "antd";
import { useLocation, Link } from "react-router-dom";
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import SiteLogo from '../../assets/images/logo.png';
import { defaultValidateMessages } from '../../utils/FormHelper';
import { useAuth } from '../../hooks/useAuth';
import { useApi } from '../../hooks/useApi';

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { login } = useAuth();
  const location = useLocation();
  const { postRequest } = useApi(false);

  const onFinish = async (values) => {
    const response = await postRequest("/Auth/Login", values);

    if (response.isSuccess) {
      login(response.data, location.state?.from?.pathname);
    }
    setLoading(false);
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Row justify='center' align='middle' style={{ height: "100%" }}>
      <Col xs={20} md={16} lg={12} xl={8} >
        <Card className="login-form-card">
          <Row justify='center' style={{ marginBottom: 24 }}>
            <img alt="Sadal" src={SiteLogo} width="200" />
          </Row>
          <Form
            validateMessages={defaultValidateMessages}
            form={form}
            name="signIn"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item name="email" rules={[{ required: true, message: "'E-posta' alanı gerekli." }, { type: "email", message: "Geçerli bir e-posta girin." }]} >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-posta" />
            </Form.Item>

            <Form.Item name="password" rules={[{ required: true, message: "'Şifre' alanı gerekli." }]} >
              <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Şifre" />
            </Form.Item>

            <Form.Item>
              <Form.Item name="rememberMe" valuePropName="checked" noStyle>
                <Checkbox>Beni hatırla</Checkbox>
              </Form.Item>

              <Link to="/forgot-password" className='login-form-forgot'>Şifremi Unuttum</Link>
            </Form.Item>

            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
                icon={<LoginOutlined />}
              >
                Giriş Yap
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default SignIn;