import axios from "axios";
import config from "../utils/config";
import { notification } from "antd";
import { useLocalStorage } from "./useLocalStorage";
import { useAuth } from "./useAuth";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const useApi = (isAuthRequired = true) => {

  const [token] = useLocalStorage("token", null);
  const [expiration] = useLocalStorage("expiration", null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const errorHandler = (error) => {

    switch (error.response?.status) {
      case 401:
        logout();
        break;
      case 403:
        navigate("/unauthorized", { replace: true });
        break;
      case 406:
        error.response.data.data?.forEach((validationMessage) => {
          console.log(validationMessage);
          notification.warning({
            message: validationMessage,
            duration: 3,
          });
        });
        break;

      default:
        const errorMessage = error.response.data?.message ? error.response.data.message : error.toString();
        notification.error({
          message: errorMessage,
          duration: 3,
        });
        break;
    }

    return error.response?.data ? error.response.data : {
      message: error.toString(),
      isSuccess: false,
    };
  };

  const sendRequest = async (endPoint, method, data, options) => {
    if (isAuthRequired && moment().isSameOrAfter(expiration)) {
      return logout();
    }

    const defaultOptions = {
      method: method,
      url: endPoint.startsWith("http") ? endPoint : config.apiUrl + endPoint,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
      },
      data: method !== "get" ? data : undefined,
      params: method === "get" ? data : undefined,
      showDefaultNotification: method !== "get"
    };

    const customizedOptions = options ? {
      ...defaultOptions,
      ...options,
      headers: options.headers ? { ...defaultOptions.headers, ...options.headers } : defaultOptions.headers
    } : defaultOptions;

    try {
      const response = await axios.request(customizedOptions);

      if (customizedOptions.showDefaultNotification) {
        const type = response.data.isSuccess ? "success" : "error";

        notification[type]({
          message: response.data.message,
          duration: 3,
        });
      }
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  };

  const getFilterQueryString = (loadOptions) => {
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
    let params = '?';
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
      'select',
      'userData',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    return params;
  };

  const getFormData = (values) => {
    const formData = new FormData();

    Object.keys(values).filter(key => values[key] !== undefined && values[key] !== null).forEach(key => formData.append(key, values[key]));
    return formData;
  }

  const getRequest = (endPoint, data, options) => sendRequest(endPoint, "get", data, options);
  const postRequest = (endPoint, data, options) => sendRequest(endPoint, "post", data, options);
  const putRequest = (endPoint, data, options) => sendRequest(endPoint, "put", data, options);
  const deleteRequest = (endPoint, data, options) => sendRequest(endPoint, "delete", data, options);
  const getRequestWithPagingQuery = (endPoint, loadOptions, data, options) => {
    loadOptions.requireTotalCount = true;
    const params = getFilterQueryString(loadOptions);
    return getRequest(endPoint + params, data, options);
  };
  const formRequest = (endPoint, method, data, options) => {
    const customizedOptions = options ?
      {
        ...options,
        headers: options.headers ? { ...options.headers, "Content-Type": "multipart/form-data" } : { "Content-Type": "multipart/form-data" }
      } :
      {
        headers: { "Content-Type": "multipart/form-data" },
      };

    const formData = data instanceof FormData ? data : getFormData(data);

    return sendRequest(endPoint, method, formData, customizedOptions);
  };



  return { getRequest, postRequest, putRequest, deleteRequest, getRequestWithPagingQuery, formRequest, getFilterQueryString, getFormData };
};