import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, InputNumber, Upload, notification, Switch, Select } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";
import { TextEditor } from "../../components/AntdCustom";
import urlSlug from "url-slug";

const BlogAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [categorySelect, setCategorySelect] = useState({ data: [], loading: false });
  const [imageFileList, setImageFileList] = useState([]);
  const [form] = Form.useForm();
  const { formRequest, getRequest, getFilterQueryString } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setCategorySelect({ ...categorySelect, loading: true });

    const query = getFilterQueryString({
      select: ["id", "title"],
      sort: [{ selector: "title", desc: false }]
    });

    const response = await getRequest(`/BlogCategories${query}`);

    if (!response.isSuccess) {
      return setCategorySelect({ ...categorySelect, loading: false });
    }

    setCategorySelect({
      data: response.data.data,
      loading: false,
    });
  };

  const onFinish = async (values) => {
    values.urlSlug = values.urlSlug ?? values.title
    values.urlSlug = urlSlug(values.urlSlug, {
      dictionary: { 'ı': 'i', 'I': 'i', },
      transformer: (fragments, separator) => fragments.map(fragment => fragment.toLowerCase()).join(separator)
    });

    const response = await formRequest(`/Blogs`, "post", values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      setImageFileList([]);
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  const imageUploadProps = {
    fileList: imageFileList,
    maxCount: 1,
    accept: ".png,.jpg,.jpeg",
    listType: "picture-card",
    onRemove: (file) => {
      const index = imageFileList.indexOf(file);
      const newFileList = imageFileList.slice();
      newFileList.splice(index, 1);
      setImageFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"]
      const isAllowed = allowedFileTypes.includes(file.type);

      if (!isAllowed) {
        notification.error({
          message: `'${file.name}' desteklenmeyen dosya tipinde.`,
          duration: 3,
        });
        return Upload.LIST_IGNORE;
      }

      setImageFileList([...fileList]);
      return false;
    },
  };

  return (
    <Form
      layout="vertical"
      validateMessages={defaultValidateMessages}
      form={form}
      name="BlogAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item
            label="Başlık"
            name="title"
            rules={[{ required: true }]}
          >
            <Input onChange={(e) => {
              form.setFieldValue("urlSlug", urlSlug(e.target.value, {
                dictionary: { 'ı': 'i', 'I': 'i', },
                transformer: (fragments, separator) => fragments.map(fragment => fragment.toLowerCase()).join(separator)
              }));
            }} />
          </Form.Item>

          <Form.Item
            label="Sayfa Url"
            name="urlSlug"
            tooltip={
              <>
                <p>'Sayfa Url' girilmezse, başlıktan bir url üretilir.</p>
                <p>Girilen url 'test-blog' ise detay sayfası '/blog/makale/test-blog' olur.</p>
              </>
            }
          >
            <Input placeholder="test-blog" />
          </Form.Item>

          <Form.Item
            label="Kategori"
            name="blogCategoryId"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Seçiniz"
              loading={categorySelect.loading}
              filterOption={(input, option) => option.children.toLocaleLowerCase("tr-TR").indexOf(input.toLocaleLowerCase("tr-TR")) >= 0}
            >
              {categorySelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Görsel"
            name="image"
            getValueFromEvent={({ file }) => file}
            rules={[{ required: true }]}
          >
            <Upload {...imageUploadProps}><Button icon={<UploadOutlined />}>{imageFileList.length === 0 ? "Yükle" : "Değiştir"}</Button></Upload>
          </Form.Item>

          <Form.Item
            label="Yayınla"
            name="published"
            initialValue={true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Sıra"
            name="order"
            initialValue={0}
          >
            <InputNumber precision={0} />
          </Form.Item>

          <Form.Item
            label="Blog İçeriği"
            name="body"
            getValueFromEvent={(_, editor) => editor.getData()}
          >
            <TextEditor />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default BlogAddForm;
