import React, { useEffect, useState } from "react";
import TenantTableFilter from "./TenantTableFilter";
import TenantAddForm from "./TenantAddForm";
import TenantUpdateForm from "./TenantUpdateForm";
import UserPage from "./User";
import DomainPage from "./Domain";

import {
  Popconfirm,
  Col,
  Row,
  Button,
  Modal,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  TeamOutlined,
  LinkOutlined,
  DeleteOutlined,
  SyncOutlined
} from "@ant-design/icons";
import { PageHeader, Table, Drawer } from "../../components/AntdCustom";
import { useApi } from "../../hooks/useApi";

const Tenant = () => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: true,
  });
  const [tableLoadOptions, setTableLoadOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
  });

  const [tenantAddFormVisible, setTenantAddFormVisible] = useState(false);
  const [tenantUpdateForm, setTenantUpdateForm] = useState({ data: {}, loading: false, visible: false });

  const [userModal, setUserModal] = useState({ tenantId: null, visible: false });
  const [domainModal, setDomainModal] = useState({ tenantId: null, visible: false });
  const [syncDealerLoading, setSyncDealerLoading] = useState(false);

  const { getRequest, getRequestWithPagingQuery, postRequest, deleteRequest } = useApi();

  useEffect(() => {
    getTableData();
  }, [tableLoadOptions]);

  const getTableData = async () => {
    setTableData({ ...tableData, loading: true });
    const response = await getRequestWithPagingQuery("/Tenants", tableLoadOptions);

    if (!response.isSuccess) {
      return setTableData({ ...tableData, loading: false });
    }

    setTableData({
      data: response.data.data,
      total: response.data.totalCount,
      loading: false,
    });
  };

  const paging = (p) => {
    setTableLoadOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const handleOnClickUpdate = async (id) => {
    setTenantUpdateForm({ ...tenantUpdateForm, loading: true, visible: true });
    const response = await getRequest(`/Tenants/${id}`);

    if (!response.isSuccess) {
      return setTenantUpdateForm({ ...tenantUpdateForm, loading: false, visible: false });
    }

    setTenantUpdateForm({ data: response.data, loading: false, visible: true });
  };

  const handleOnClickDelete = async (id) => {
    const response = await deleteRequest(`/Tenants/${id}`);

    if (response.isSuccess) {
      getTableData();
    }
  };

  const onSuccessAddTenant = () => {
    getTableData();
    setTenantAddFormVisible(false);
  };

  const onSuccessUpdateTenant = () => {
    getTableData();
    setTenantUpdateForm({ ...tenantUpdateForm, visible: false, data: {} });
  };

  const onClearTableFilter = () => {
    setTableLoadOptions({
      ...tableLoadOptions,
      skip: 0,
      current: 1,
      filter: null
    });
  };

  const onFinishTableFilter = (filter) => {
    setTableLoadOptions((prevState) => {
      return { ...prevState, skip: 0, current: 1, filter: filter };
    });
  };

  const onClickTenantUser = (tenantId) => setUserModal({ tenantId, visible: true });

  const onClickTenantDomain = (tenantId) => setDomainModal({ tenantId, visible: true });

  const onClickSyncDealers = async () => {
    setSyncDealerLoading(true);
    await postRequest("/SadalIntegrations/SyncDealers");
    setSyncDealerLoading(false);
  };

  const columns = [
    {
      title: "Bayi Kodu",
      dataIndex: "dealerCode",
    },
    {
      title: "Bayi Adı",
      dataIndex: "dealerName",
    },
    {
      title: "Site Başlığı",
      dataIndex: "siteTitle",
    },
    {
      title: "Kullanıcılar",
      align: "center",
      render: (row) => (
        <Button type="link" onClick={() => onClickTenantUser(row.id)} icon={<TeamOutlined />} />
      ),
    },
    {
      title: "Alan Adları",
      align: "center",
      render: (row) => (
        <Button type="link" onClick={() => onClickTenantDomain(row.id)} icon={<LinkOutlined />} />
      ),
    },
    {
      title: "Düzenle",
      width: "1%",
      align: "center",
      render: (row) => (
        <Button type="link" onClick={() => handleOnClickUpdate(row.id)} icon={<EditOutlined style={{ color: "orange" }} />} />
      ),
    },
    {
      title: "Sil",
      width: "1%",
      align: "center",
      render: (row) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(row.id)}
        >
          <Button type="link" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Firmalar"
        extra={
          <>
            <Button
              onClick={onClickSyncDealers}
              icon={<SyncOutlined />}
              loading={syncDealerLoading}
            >
              Sadal Bayi Senkronizasyonu
            </Button>
            <Button
              type="primary"
              onClick={() => setTenantAddFormVisible(true)}
              icon={<PlusOutlined />}
            >
              Firma Ekle
            </Button>
          </>
        }
      />

      <TenantTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      />

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            onChange={paging}
            pagination={{
              defaultPageSize: tableLoadOptions.take,
              current: tableLoadOptions.current,
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
          />
        </Col>
      </Row>

      <Drawer
        id="addTenantDrawer"
        title="Firma Ekle"
        onClose={() => setTenantAddFormVisible(false)}
        visible={tenantAddFormVisible}
      >
        <TenantAddForm onSuccess={onSuccessAddTenant} />
      </Drawer>

      <Drawer
        id="updateTenantDrawer"
        title="Firma Güncelle"
        onClose={() => setTenantUpdateForm({ data: {}, loading: false, visible: false })}
        visible={tenantUpdateForm.visible}
        loading={tenantUpdateForm.loading}
      >
        <TenantUpdateForm
          data={tenantUpdateForm.data}
          onSuccess={onSuccessUpdateTenant}
        />
      </Drawer>

      <Modal
        id="userModal"
        centered
        visible={userModal.visible}
        onOk={() => setUserModal({ ...userModal, visible: false })}
        onCancel={() => setUserModal({ ...userModal, visible: false })}
        width={1000}
        footer={null}
      >
        <UserPage tenantId={userModal.tenantId} />
      </Modal>

      <Modal
        id="domainModal"
        centered
        visible={domainModal.visible}
        onOk={() => setDomainModal({ ...userModal, visible: false })}
        onCancel={() => setDomainModal({ ...userModal, visible: false })}
        width={1000}
        footer={null}
      >
        <DomainPage tenantId={domainModal.tenantId} />
      </Modal>

    </>
  );
};

export default Tenant;
