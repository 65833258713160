import React from "react";
import { Avatar, Popover, Menu } from "antd";
import { UserOutlined, RedoOutlined, LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const { claims, logout } = useAuth();
  const navigate = useNavigate();

  const menuItems = [
    {
      type: "group",
      label: `${claims.name} ${claims.surname}`,
      children: [
        {
          type: "divider",
        },
        {
          onClick: () => navigate("/change-password"),
          icon: <RedoOutlined />,
          label: "Şifremi Değiştir"
        },
        {
          onClick: logout,
          icon: <LogoutOutlined />,
          label: "Çıkış Yap"
        },]
    },
  ];

  return (
    <Popover
      placement="bottomRight"
      content={<Menu
        style={{ border: 0 }}
        items={menuItems}
        selectable={false}
        mode="inline"
      />}
      trigger="click"
    >
      <Avatar
        icon={<UserOutlined />}
        className="gx-size-40 gx-pointer gx-bg-secondary"
      />
    </Popover>
  );
};

export default UserProfile;
