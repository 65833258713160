import React, { useState } from "react";
import { Button, Form, Row, Col, Input } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { PageHeader } from '../../components/AntdCustom';
import { useApi } from '../../hooks/useApi';

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { postRequest } = useApi();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const response = await postRequest(`/Auth/ChangePassword`, values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      navigate("/");
    }
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <>
      <PageHeader
        title="Şifremi Değiştir"
        onBack={() => navigate(-1)}
      />

      <Row className="gx-mt-5" justify="center">
        <Col xs={24} md={12}>
          <Form
            {...defaultFormItemLayout}
            validateMessages={defaultValidateMessages}
            form={form}
            name="changePassword"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={24}>

                <Form.Item
                  label="Eski Şifre"
                  name="oldPassword"
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label="Yeni Şifre"
                  name="newPassword"
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>

                <Form.Item
                  label="Yeni Şifre Tekrar"
                  name="newPasswordAgain"
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>

                <Col style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    htmlType="submit"
                    onClick={() => setLoading(true)}
                    loading={loading}
                  >
                    Kaydet
                  </Button>
                </Col>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default ChangePassword;
