import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CustomTextEditor = ({ data, ...rest }) => {

  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      {...rest}
    />
  )
}

export default CustomTextEditor;