import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, InputNumber, Switch } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";
import urlSlug from "url-slug";

const CategoryUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { putRequest } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFinish = async (values) => {
    values.urlSlug = values.urlSlug ?? values.title
    values.urlSlug = urlSlug(values.urlSlug, {
      dictionary: { 'ı': 'i', 'I': 'i', },
      transformer: (fragments, separator) => fragments.map(fragment => fragment.toLowerCase()).join(separator)
    });

    const response = await putRequest(`/BlogCategories`, values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Form
      {...defaultFormItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="CategoryUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item name="id" hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Başlık"
            name="title"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Sayfa Url"
            name="urlSlug"
            tooltip={
              <>
                <p>'Sayfa Url' girilmezse, başlıktan bir url üretilir.</p>
                <p>Girilen url 'test-kategori' ise detay sayfası '/blog/kategori/test-kategori' olur.</p>
              </>
            }
          >
            <Input placeholder="test-kategori" />
          </Form.Item>

          <Form.Item
            label="Yayınla"
            name="published"
            initialValue={true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Sıra"
            name="order"
            initialValue={0}
          >
            <InputNumber precision={0} />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default CategoryUpdateForm;
