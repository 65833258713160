import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'antd';
import { useApi } from "../../hooks/useApi";
import HomeSettingsUpdateForm from './HomeSettingsUpdateForm';
import { PageHeader } from "../../components/AntdCustom";

const SiteSettings = () => {
  const [updateForm, setUpdateForm] = useState({ data: {}, loading: false });
  const { getRequest } = useApi();

  useEffect(() => {
    getUpdateFormData();
  }, [])


  const getUpdateFormData = async () => {
    setUpdateForm({ ...updateForm, loading: true });
    const response = await getRequest(`/HomeSettings/GetByTenant`);

    if (!response.isSuccess) {
      return setUpdateForm({ ...updateForm, loading: false });
    }

    setUpdateForm({ data: response.data, loading: false });
  };

  const onSuccessUpdate = () => {
    getUpdateFormData();
  };

  return (
    <>
      <PageHeader
        title="Ana Sayfa Ayarları"
      />

      <Row className='gx-mt-3' justify='center'>
        <Col xs={24} lg={24}>
          <Card>
            <HomeSettingsUpdateForm
              data={updateForm.data}
              loading={updateForm.loading}
              onSuccess={onSuccessUpdate}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default SiteSettings;