import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";

const UserAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { postRequest } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFinish = async (values) => {
    const response = await postRequest(`/Users`, values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Form
      {...defaultFormItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="UserAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item name="tenantId" hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item label="Ad" name="firstName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Soyad" name="lastName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="E-posta"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Şifre"
            name="password"
            tooltip="Kullanıcı şifresi girilmez ise sistem tarafından şifre üretilip kullanıcıya e-posta yollanır."
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default UserAddForm;
