import React, { useState } from 'react';
import { Button, Col, Layout, Row } from 'antd';
import { useLocation, Navigate, useOutlet, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import SiteLogo from '../../assets/images/logo.png';
import UserProfile from '../../components/UserProfile';
import SideMenu from '../../components/SideMenu';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  const [siderCollepsed, setSiderCollepsed] = useState(false);
  const [siderBroken, setSiderBroken] = useState(false);
  const { token } = useAuth();
  const location = useLocation();
  const outlet = useOutlet();

  return !token ? (
    <Navigate to="/signin" state={{ from: location }} />
  ) :
    (
      <Layout className='dashboard-layout'>
        <Sider
          theme="light"
          breakpoint="lg"
          collapsible={true}
          collapsedWidth={siderBroken ? 0 : undefined}
          collapsed={siderCollepsed}
          trigger={null}
          onBreakpoint={(broken) => setSiderBroken(broken)}
        >
          <div className="site-logo">
            <Link to="/"><img alt='Sadal' src={SiteLogo} /></Link>
          </div>
          <SideMenu />
        </Sider>
        <Layout>
          <Header className="site-header">
            <Row justify="space-between" align="middle">
              <Col>
                <Button
                  type="link"
                  size="large"
                  className="gx-mb-0"
                  onClick={() => setSiderCollepsed(prevState => !prevState)}
                  icon={siderCollepsed ? <MenuUnfoldOutlined className="gx-size-24" style={{ fontSize: "120%" }} /> : <MenuFoldOutlined className="gx-size-24" style={{ fontSize: "120%" }} />}
                />
              </Col>
              <Col>
                <UserProfile />
              </Col>
            </Row>
          </Header>
          <Content className="site-content gx-bg-light gx-p-3">
            <div className='gx-bg-white gx-p-3' style={{ minHeight: "100%" }}>
              {outlet}
            </div>
          </Content>
        </Layout>
      </Layout>


    );
}

export default Dashboard
