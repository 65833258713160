import React, { useState } from 'react';
import { Button, Form, Row, Col, Input, Card } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { UserOutlined, ArrowLeftOutlined, SendOutlined } from "@ant-design/icons";
import { defaultValidateMessages } from '../../utils/FormHelper';
import { useApi } from '../../hooks/useApi';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { postRequest } = useApi(false);

  const onFinish = async (values) => {
    const response = await postRequest("/Auth/ForgotPassword", values);

    if (response.isSuccess) {
      form.resetFields();
      navigate("/signin");
    }
    setLoading(false);
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Row justify='center' align='middle' style={{ height: "100%" }}>
      <Col xs={20} md={16} lg={12} xl={8} >
        <Card className="login-form-card" title={<Link to="/signin"><ArrowLeftOutlined /> Geri Dön</Link>} >
          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <p>E-posta adresinizi girip gönderin.</p>
              <p>Gelen e-postadaki linke tıklayarak şifrenizi yenileyebilirsiniz.</p>
            </Col>
          </Row>
          <Form
            validateMessages={defaultValidateMessages}
            form={form}
            name="forgotPassword"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item name="email" rules={[{ required: true, message: "'E-posta' alanı gerekli." }, { type: "email", message: "Geçerli bir e-posta girin." }]} >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-posta" />
            </Form.Item>

            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
                icon={<SendOutlined />}
              >
                Gönder
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default ForgotPassword;