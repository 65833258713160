import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Upload, InputNumber, notification, Switch } from "antd";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";

const SlideUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageFileList, setImageFileList] = useState([]);
  const [mobileImageFileList, setMobileImageFileList] = useState([]);
  const [form] = Form.useForm();
  const { formRequest, putRequest } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);

      setImageFileList(props.data.image ? [{ uid: -1, status: 'done', url: props.data.image }] : []);
      setMobileImageFileList(props.data.mobileImage ? [{ uid: -1, status: 'done', url: props.data.mobileImage }] : []);

    }
  }, [props.data]);

  const onFinish = async (values) => {
    values.removeMobileImage = mobileImageFileList.length === 0;

    const response = props.data?.isDefault ? await putRequest("/HomeSlides/UpdateDefaultSlide", values) : await formRequest(`/HomeSlides`, "put", values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  const imageUploadProps = {
    fileList: imageFileList,
    maxCount: 1,
    accept: ".png,.jpg,.jpeg",
    listType: "picture-card",
    onRemove: (file) => {
      const index = imageFileList.indexOf(file);
      const newFileList = imageFileList.slice();
      newFileList.splice(index, 1);
      setImageFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"]
      const isAllowed = allowedFileTypes.includes(file.type);

      if (!isAllowed) {
        notification.error({
          message: `'${file.name}' desteklenmeyen dosya tipinde.`,
          duration: 3,
        });
        return Upload.LIST_IGNORE;
      }

      setImageFileList([...fileList]);
      return false;
    },
  };

  const mobileImageUploadProps = {
    fileList: mobileImageFileList,
    maxCount: 1,
    accept: ".png,.jpg,.jpeg",
    listType: "picture-card",
    onRemove: (file) => {
      const index = mobileImageFileList.indexOf(file);
      const newFileList = mobileImageFileList.slice();
      newFileList.splice(index, 1);
      setMobileImageFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"]
      const isAllowed = allowedFileTypes.includes(file.type);

      if (!isAllowed) {
        notification.error({
          message: `'${file.name}' desteklenmeyen dosya tipinde.`,
          duration: 3,
        });
        return Upload.LIST_IGNORE;
      }

      setMobileImageFileList([...fileList]);
      return false;
    },
  };

  return (
    <Form
      {...defaultFormItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="SlideUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item name="id" hidden={true}>
            <Input />
          </Form.Item>

          {props.data?.isDefault ? (
            <>
              <Form.Item
                label="Göster"
                name="visible"
                initialValue={true}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Sıra"
                name="order"
                initialValue={0}
              >
                <InputNumber precision={0} />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="Başlık"
                name="title"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Görsel"
                name="image"
                getValueFromEvent={({ file }) => file}
                rules={[{ required: true }]}
              >
                <Upload {...imageUploadProps}><Button icon={<UploadOutlined />}>{imageFileList.length === 0 ? "Yükle" : "Değiştir"}</Button></Upload>
              </Form.Item>

              <Form.Item
                label="Mobil Görsel"
                name="mobileImage"
                getValueFromEvent={({ file }) => file}
                tooltip="Yüklenmez ise masaüstü görseli gösterilir."
              >
                <Upload {...mobileImageUploadProps}><Button icon={<UploadOutlined />}>{mobileImageFileList.length === 0 ? "Yükle" : "Değiştir"}</Button></Upload>
              </Form.Item>

              <Form.Item
                label="Url"
                name="url"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Göster"
                name="visible"
                initialValue={true}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Sıra"
                name="order"
                initialValue={0}
              >
                <InputNumber precision={0} />
              </Form.Item>
            </>
          )}

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default SlideUpdateForm;
