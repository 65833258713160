import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, InputNumber, Switch, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";

const AdminStihlProductSlideAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [productSelect, setProductSelect] = useState({
    data: [],
    loading: false,
  });
  const [form] = Form.useForm();
  const { postRequest, getRequestWithPagingQuery } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onStihlProductSearch = async (val) => {
    if (val.length < 3) {
      return setProductSelect({ data: [], loading: false });
    }

    setProductSelect({ ...productSelect, loading: true });
    const response = await getRequestWithPagingQuery("/StihlProducts", {
      select: ["id", "title"],
      filter: [["id", "contains", val], "or", ["title", "contains", val]]
    });
    setProductSelect({ data: response.isSuccess ? response.data.data : [], loading: false });
  };

  const onFinish = async (values) => {
    const response = await postRequest(`/StihlProductSlides`, values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Form
      {...defaultFormItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="ProductSlideAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item name="tenantId" hidden={true} rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Ürün"
            name="productId"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Aramak için en az 3 harf giriniz.."
              onSearch={onStihlProductSearch}
              loading={productSelect.loading}
              filterOption={(input, option) => option.searchValue.toLocaleLowerCase("tr-TR").indexOf(input.toLocaleLowerCase("tr-TR")) >= 0}
            >
              {productSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id} searchValue={`${item.id}-${item.title}`}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Ana Sayfada Göster"
            name="showAtHome"
            initialValue={true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Sıra"
            name="order"
            initialValue={0}
          >
            <InputNumber precision={0} />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default AdminStihlProductSlideAddForm;
