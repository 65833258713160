import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";

const TenantUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [dealerSelect, setDealerSelect] = useState({
    data: [],
    loading: false,
  });
  const [form] = Form.useForm();
  const { getRequest, putRequest, getFilterQueryString } = useApi();

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onDealerSearch = async (val) => {
    if (val.length < 2) {
      return setDealerSelect({ data: [], loading: false });
    }

    const query = getFilterQueryString({
      select: ["id", "code", "name", "shortName", "address"],
      filter: [["tenantId", "=", null], "and", [["shortName", "contains", val], "or", ["name", "contains", val], "or", ["code", "contains", val]]]
    });
    setDealerSelect({ ...dealerSelect, loading: true });
    const response = await getRequest(`/Dealers${query}`);
    setDealerSelect({ data: response.isSuccess ? response.data.data : [], loading: false });
  };

  const onFinish = async (values) => {
    const response = await putRequest(`/Tenants`, values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Form
      {...defaultFormItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="TenantUpdate"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item name="id" hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Site Başlığı"
            name="siteTitle"
            tooltip="Bayi adı uzun ise girilmelidir. Websitesinde bayi adı yerine kullanılır."
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Bayi"
            name="dealerId"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Aramak için en az 2 harf giriniz.."
              onSearch={onDealerSearch}
              loading={dealerSelect.loading}
              filterOption={(input, option) => option.searchValue.toLocaleLowerCase("tr-TR").indexOf(input.toLocaleLowerCase("tr-TR")) >= 0}
            >

              {props.data?.dealerId && (
                <Select.Option
                  key={props.data.dealerId}
                  value={props.data.dealerId}
                  searchValue={`${props.data.dealerCode}-${props.data.dealerShortName}-${props.data.dealerName}`}
                >
                  <>
                    <p><strong>{props.data.dealerName}</strong></p>
                    <p>{`${props.data.dealerCode} - ${props.data.dealerShortName}`}<br />{props.data.dealerAddress}</p>
                  </>
                </Select.Option>
              )}

              {dealerSelect.data.filter((x) => x.id !== props.data?.dealerId).map((item) => (
                <Select.Option key={item.id} value={item.id} searchValue={`${item.code}-${item.shortName}-${item.name}`}>
                  <>
                    <p><strong>{item.name}</strong></p>
                    <p>{`${item.code} - ${item.shortName}`}<br />{item.address}</p>
                  </>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Güncelle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default TenantUpdateForm;
