import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Spin, Divider } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";

const AboutSettingsUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const { putRequest } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFinish = async (values) => {
    const response = await putRequest("/HomeSettings", values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Spin spinning={props.loading}>
      <Form
        {...defaultFormItemLayout}
        validateMessages={defaultValidateMessages}
        form={form}
        name="SiteSettingsUpdate"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={24}>

            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>

            <Divider>YouTube Videoları</Divider>

            <Form.Item label="1. Video" required >
              <Input.Group compact>
                <Button
                  type="primary"
                  disabled
                >
                  www.youtube.com/watch?v=
                </Button>
                <Form.Item noStyle name="youTubeVideoId1" label="1. Video" rules={[{ required: true }]}>
                  <Input style={{ width: '50%' }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item label="2. Video" required >
              <Input.Group compact>
                <Button
                  type="primary"
                  disabled
                >
                  www.youtube.com/watch?v=
                </Button>
                <Form.Item noStyle name="youTubeVideoId2" label="2. Video" rules={[{ required: true }]}>
                  <Input style={{ width: '50%' }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item label="3. Video" required >
              <Input.Group compact>
                <Button
                  type="primary"
                  disabled
                >
                  www.youtube.com/watch?v=
                </Button>
                <Form.Item noStyle name="youTubeVideoId3" label="3. Video" rules={[{ required: true }]}>
                  <Input style={{ width: '50%' }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item label="4. Video" required >
              <Input.Group compact>
                <Button
                  type="primary"
                  disabled
                >
                  www.youtube.com/watch?v=
                </Button>
                <Form.Item noStyle name="youTubeVideoId4" label="4. Video" rules={[{ required: true }]}>
                  <Input style={{ width: '50%' }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Col style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Güncelle
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
export default AboutSettingsUpdateForm;
