import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Input, Card } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import { defaultValidateMessages } from '../../utils/FormHelper';
import { useApi } from '../../hooks/useApi';

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { postRequest } = useApi(false);
  const { resetCode } = useParams();

  useEffect(() => {
    checkResetCode();
  }, []);

  const checkResetCode = async () => {
    const response = await postRequest(`/Auth/ResetCodeIsValid/${resetCode}`, null, { showDefaultNotification: false });

    if (response.isSuccess && response.data) {
      return setPageLoading(false);
    }

    navigate("/signin");
  };

  const onFinish = async (values) => {
    const response = await postRequest("/Auth/ResetPassword", { ...values, resetPasswordCode: resetCode });

    if (response.isSuccess) {
      form.resetFields();
      navigate("/signin");
    }
    setLoading(false);
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Row justify='center' align='middle' style={{ height: "100%" }}>
      <Col xs={20} md={16} lg={12} xl={8} >
        <Card className="login-form-card" title="Şifre Sıfırla" loading={pageLoading}>
          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <p>E-posta adresinizi girip gönderin.</p>
              <p>Gelen e-postadaki linke tıklayarak şifrenizi yenileyebilirsiniz.</p>
            </Col>
          </Row>
          <Form
            validateMessages={defaultValidateMessages}
            form={form}
            name="forgotPassword"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >

            <Form.Item name="password" rules={[{ required: true, message: "'Şifre' alanı gerekli." }]} >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Şifre"
                autoComplete="new-password"
              />
            </Form.Item>

            <Form.Item name="passwordAgain" rules={[{ required: true, message: "'Şifre Tekrar' alanı gerekli." }]} >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Şifre Tekrar"
                autoComplete="new-password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Sıfırla
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default ResetPassword;