import React from 'react'
import { Drawer, Spin } from 'antd';

const CustomDrawer = ({ loading, children, ...rest }) => {

  return (
    <Drawer
      width={600}
      {...rest}
    >
      <Spin spinning={loading === true}>
        {children}
      </Spin>
    </Drawer>
  )
}

export default CustomDrawer;