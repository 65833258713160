import React from 'react'
import { Result } from 'antd';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sayfa bulunamadı."
      extra={<Link to="/">Anasayfa'ya dön</Link>}
    />
  )
}

export default NotFound;