import React from 'react'
import { Empty, Table } from 'antd';

const CustomTable = ({ pagination, children, ...rest }) => {

  return (
    <Table
      locale={{
        emptyText: (<Empty />),
      }}
      style={{ overflowX: "auto", maxHeight: "100%", width: "100%" }}
      rowKey="id"
      filterable={true}
      sortable={true}
      pageable={true}
      pagination={{
        pageSizeOptions: ["15", "25", "50", "100"],
        defaultPageSize: 15,
        ...pagination
      }}
      size="small"
      {...rest}
    >
      {children}
    </Table>
  )
}

export default CustomTable;