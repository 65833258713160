import React, { useEffect, useState } from "react";
// import SlideTableFilter from "./SlideTableFilter";
// import SlideAddForm from "./AdminSlideAddForm";
import SlideUpdateForm from "./AdminSlideUpdateForm";

import {
  Popconfirm,
  Col,
  Row,
  Button,
  Image,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  PictureOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { PageHeader, Table, Drawer } from "../../components/AntdCustom";
import { useApi } from "../../hooks/useApi";

const AdminSlide = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: false,
  });
  const [tableLoadOptions, setTableLoadOptions] = useState({
    skip: 0,
    take: 15,
    current: 1,
  });
  const [addFormVisible, setAddFormVisible] = useState(false);
  const [updateForm, setUpdateForm] = useState({ data: {}, loading: false, visible: false });

  const { getRequestWithPagingQuery, getRequest, deleteRequest } = useApi();

  useEffect(() => {
    getTableData();
  }, [tableLoadOptions]);

  const getTableData = async () => {
    setTableData({ ...tableData, loading: true });
    const response = await getRequestWithPagingQuery("/DefaultHomeSlides", tableLoadOptions);

    if (!response.isSuccess) {
      return setTableData({ ...tableData, loading: false });
    }

    setTableData({
      data: response.data.data,
      total: response.data.totalCount,
      loading: false,
    });
  };

  const paging = (p) => {
    setTableLoadOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const handleOnClickUpdate = async (id) => {
    setUpdateForm({ ...updateForm, loading: true, visible: true });
    const response = await getRequest(`/DefaultHomeSlides/${id}`);

    if (!response.isSuccess) {
      return setUpdateForm({ ...updateForm, loading: false, visible: false });
    }

    setUpdateForm({ data: response.data, loading: false, visible: true });
  };

  const handleOnClickDelete = async (id) => {
    const response = await deleteRequest(`/DefaultHomeSlides/${id}`);

    if (response.isSuccess) {
      getTableData();
    }
  };

  const onClearTableFilter = () => {
    setTableLoadOptions({ ...tableLoadOptions, skip: 0, current: 1 });
  };

  const onFinishTableFilter = (query) => {
    setTableLoadOptions((prevState) => { return { ...prevState, skip: 0, current: 1, filter: query }; });
  };

  const onSuccessAdd = () => {
    getTableData();
    setAddFormVisible(false);
  };

  const onSuccessUpdate = () => {
    getTableData();
    setUpdateForm({ ...updateForm, visible: false, data: {} });
  };

  const columns = [
    {
      title: "Sıra",
      dataIndex: "defaultSlideId",
      align: "center",
    },
    {
      title: "Görsel",
      align: "center",
      render: (row) => row.image ? (
        <Image height={100} src={row.image} alt={row.name} />
      ) : (
        <PictureOutlined className="gx-text-secondary" style={{ fontSize: 100 }} />
      ),
    },
    {
      title: "Mobil Görsel",
      align: "center",
      render: (row) => row.mobileImage ? (
        <Image height={100} src={row.mobileImage} alt={row.name} />
      ) : (
        <PictureOutlined className="gx-text-secondary" style={{ fontSize: 100 }} />
      ),
    },
    {
      title: "Başlık",
      dataIndex: "title",
    },
    // {
    //   title: "Göster",
    //   align: "center",
    //   render: (row) => row.visible ? (
    //     <CheckCircleOutlined className="gx-text-success" />
    //   ) : (
    //     <CloseCircleOutlined className="gx-text-danger" />
    //   ),
    // },
    // {
    //   title: "Varsayılan",
    //   align: "center",
    //   render: (row) => row.isDefault ? (
    //     <CheckCircleOutlined className="gx-text-success" />
    //   ) : (
    //     <CloseCircleOutlined className="gx-text-danger" />
    //   ),
    // },
    {
      title: "Düzenle",
      width: "1%",
      align: "center",
      render: (row) => (
        <Button type="link" onClick={() => handleOnClickUpdate(row.id)} icon={<EditOutlined style={{ color: "orange" }} />} />
      ),
    },
    // {
    //   title: "Sil",
    //   width: "1%",
    //   align: "center",
    //   render: (row) => row.isDefault ? null : (
    //     <Popconfirm
    //       title="Silmek istiyor musunuz?"
    //       okText="Evet"
    //       cancelText="Hayır"
    //       onConfirm={() => handleOnClickDelete(row.id)}
    //     >
    //       <Button type="link" danger icon={<DeleteOutlined />} />
    //     </Popconfirm>
    //   ),
    // },
  ];

  return (
    <>
      <PageHeader
        title="Varsayılan Ana Sayfa Slaytları"
      // extra={
      //   <Button
      //     type="primary"
      //     icon={<PlusOutlined />}
      //     onClick={() => setAddFormVisible(true)}
      //   >
      //     Slayt Ekle
      //   </Button>
      // }
      />

      {/* <SlideTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      /> */}

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            onChange={paging}
            pagination={{
              defaultPageSize: tableLoadOptions.take,
              current: tableLoadOptions.current,
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
          />
        </Col>
      </Row>

      {/* <Drawer
        id="addSlideDrawer"
        title="Slayt Ekle"
        onClose={() => setAddFormVisible(false)}
        visible={addFormVisible}
      >
        <SlideAddForm onSuccess={onSuccessAdd} />
      </Drawer> */}

      <Drawer
        id="updateSlideDrawer"
        title="Slayt Güncelle"
        onClose={() => setUpdateForm({ data: {}, loading: false, visible: false })}
        visible={updateForm.visible}
        loading={updateForm.loading}
      >
        <SlideUpdateForm
          data={updateForm.data}
          onSuccess={onSuccessUpdate}
        />
      </Drawer>

    </>
  );
};

export default AdminSlide;
