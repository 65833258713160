import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Spin } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";
import { TextEditor } from "../../components/AntdCustom";

const AboutSettingsUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const { putRequest } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFinish = async (values) => {
    const response = await putRequest("/AboutSettings", values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Spin spinning={props.loading}>
      <Form
        layout="vertical"
        validateMessages={defaultValidateMessages}
        form={form}
        name="SiteSettingsUpdate"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={24}>

            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Sayfa İçeriği"
              name="body"
              getValueFromEvent={(_, editor) => editor.getData()}
            >
              <TextEditor data={props.data?.body ? props.data?.body : undefined} />
            </Form.Item>

            <Form.Item
              label="Misyonumuz"
              name="mission"
              getValueFromEvent={(_, editor) => editor.getData()}
            >
              <TextEditor data={props.data?.mission ? props.data?.mission : undefined} />
            </Form.Item>

            <Form.Item
              label="Vizyonumuz"
              name="vision"
              getValueFromEvent={(_, editor) => editor.getData()}
            >
              <TextEditor data={props.data?.vision ? props.data?.vision : undefined} />
            </Form.Item>

            <Col style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Güncelle
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
export default AboutSettingsUpdateForm;
