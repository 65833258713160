import React, { useEffect, useState } from "react";
// import ProductSlideTableFilter from "./ProductSlideTableFilter";
import AdminStihlProductSlideAddForm from "./AdminStihlProductSlideAddForm";
import AdminStihlProductSlideUpdateForm from "./AdminStihlProductSlideUpdateForm";

import {
  Popconfirm,
  Col,
  Row,
  Button,
  Image,
  Select,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { PageHeader, Table, Drawer } from "../../components/AntdCustom";
import { useApi } from "../../hooks/useApi";

const AdminStihlProductSlide = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    total: 0,
    loading: false,
  });
  const [tableLoadOptions, setTableLoadOptions] = useState({
    skip: 0,
    take: 16,
    current: 1,
  });
  const [addFormVisible, setAddFormVisible] = useState(false);
  const [updateForm, setUpdateForm] = useState({ data: {}, loading: false, visible: false });

  const [tenantSelect, setTenantSelect] = useState({
    data: [{ id: "00000000-0000-0000-0000-000000000001", dealerName: "Varsayılan" }],
    loading: true,
    activeId: "00000000-0000-0000-0000-000000000001"
  });

  const { getRequestWithPagingQuery, getRequest, deleteRequest, getFilterQueryString } = useApi();

  useEffect(() => {
    getTableData();
  }, [tableLoadOptions, tenantSelect.activeId]);

  useEffect(() => {
    getTenants();
  }, []);

  const getTableData = async (tenantId) => {
    setTableData({ ...tableData, loading: true });
    tableLoadOptions.userData = { tenantId: tenantSelect.activeId };
    const response = await getRequestWithPagingQuery("/StihlProductSlides", tableLoadOptions);

    if (!response.isSuccess) {
      return setTableData({ ...tableData, loading: false });
    }

    setTableData({
      data: response.data.data,
      total: response.data.totalCount,
      loading: false,
    });
  };

  const getTenants = async () => {
    const query = getFilterQueryString({
      select: ["id", "dealerCode", "dealerName", "dealerShortName", "dealerAddress", "siteTitle"],
      take: -1
    });
    const response = await getRequest(`/Tenants${query}`);

    if (!response.isSuccess) {
      return setTenantSelect({ ...tenantSelect, loading: false });
    }

    setTenantSelect({
      ...tenantSelect,
      data: [{ id: "00000000-0000-0000-0000-000000000001", dealerName: "Varsayılan" }, ...response.data.data],
      loading: false,
    });
  };

  const paging = (p) => {
    setTableLoadOptions((prevState) => {
      return {
        ...prevState,
        skip: (p.current - 1) * prevState.take,
        take: p.pageSize,
        current: p.current,
      };
    });
  };

  const handleOnClickUpdate = async (id) => {
    setUpdateForm({ ...updateForm, loading: true, visible: true });
    const response = await getRequest(`/StihlProductSlides/${id}`);

    if (!response.isSuccess) {
      return setUpdateForm({ ...updateForm, loading: false, visible: false });
    }

    setUpdateForm({ data: response.data, loading: false, visible: true });
  };

  const handleOnClickDelete = async (id) => {
    const response = await deleteRequest(`/StihlProductSlides/${id}`);

    if (response.isSuccess) {
      getTableData();
    }
  };

  const onClearTableFilter = () => {
    setTableLoadOptions({ ...tableLoadOptions, skip: 0, current: 1 });
  };

  const onFinishTableFilter = (query) => {
    setTableLoadOptions((prevState) => { return { ...prevState, skip: 0, current: 1, filter: query }; });
  };

  const onSuccessAdd = () => {
    getTableData();
    setAddFormVisible(false);
  };

  const onSuccessUpdate = () => {
    getTableData();
    setUpdateForm({ ...updateForm, visible: false, data: {} });
  };

  const columns = [
    {
      title: "Sıra",
      dataIndex: "order",
      align: "center",
    },
    {
      title: "Görsel",
      align: "center",
      render: (row) => (
        <Image height={100} src={`https://ebayi.sadal.com.tr/static/RESIMLER/${row.productId.padStart(11, "0")}/${row.productId.padStart(11, "0")}-1.jpg`} alt={row.productTitle} preview={false} />
      ),
    },
    {
      title: "Başlık",
      dataIndex: "productTitle",
    },
    {
      title: "Ana Sayfada Göster",
      width: "15%",
      align: "center",
      render: (row) => row.showAtHome ? (
        <CheckCircleOutlined className="gx-text-success" />
      ) : (
        <CloseCircleOutlined className="gx-text-danger" />
      ),
    },
    {
      title: "Düzenle",
      width: "1%",
      align: "center",
      render: (row) => (
        <Button type="link" onClick={() => handleOnClickUpdate(row.id)} icon={<EditOutlined style={{ color: "orange" }} />} />
      ),
    },
    {
      title: "Sil",
      width: "1%",
      align: "center",
      render: (row) => (
        <Popconfirm
          title="Silmek istiyor musunuz?"
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => handleOnClickDelete(row.id)}
        >
          <Button type="link" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Stihl Ürünleri"
        extra={
          [
            <Select
              showSearch
              loading={tenantSelect.loading}
              value={tenantSelect.activeId}
              onSelect={value => setTenantSelect({ ...tenantSelect, activeId: value })}
              dropdownMatchSelectWidth={400}
              style={{ width: 250 }}
              key={1}
              filterOption={(input, option) => option.searchValue.toLocaleLowerCase("tr-TR").indexOf(input.toLocaleLowerCase("tr-TR")) >= 0}
            >
              {tenantSelect.data.map((item) => item.id === "00000000-0000-0000-0000-000000000001" ? (
                <Select.Option key={item.id} value={item.id} searchValue={`${item.dealerCode}-${item.dealerShortName}-${item.dealerName}-${item.siteTitle}`}>
                  <>
                    <p><strong>{item.dealerName}</strong></p>
                    <p> </p>
                  </>
                </Select.Option>) : (
                <Select.Option key={item.id} value={item.id} searchValue={`${item.dealerCode}-${item.dealerShortName}-${item.dealerName}-${item.siteTitle}`}>
                  <>
                    <p><strong>{item.dealerName}</strong></p>
                    <p>{`${item.dealerCode} - ${item.dealerShortName}`}<br />{item.dealerAddress}</p>
                  </>
                </Select.Option>
              ))}
            </Select>
            ,
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddFormVisible(true)}
              key={2}
            >
              Stihl Ürünü Ekle
            </Button>
          ]
        }
      />

      {/* <ProductSlideTableFilter
        onClearFilter={onClearTableFilter}
        onFinishFilter={onFinishTableFilter}
        style={{ marginTop: 12, marginBottom: 12 }}
      /> */}

      <Row id="tableArea">
        <Col span={24}>
          <Table
            columns={columns}
            onChange={paging}
            pagination={{
              defaultPageSize: tableLoadOptions.take,
              current: tableLoadOptions.current,
              total: tableData.total,
            }}
            dataSource={tableData.data}
            loading={tableData.loading}
          />
        </Col>
      </Row>

      <Drawer
        id="addProductSlideDrawer"
        title="Stihl Ürünü Ekle"
        onClose={() => setAddFormVisible(false)}
        visible={addFormVisible}
      >
        <AdminStihlProductSlideAddForm
          data={{ tenantId: tenantSelect.activeId }}
          onSuccess={onSuccessAdd}
        />
      </Drawer>

      <Drawer
        id="updateProductSlideDrawer"
        title="Stihl Ürünü Güncelle"
        onClose={() => setUpdateForm({ data: {}, loading: false, visible: false })}
        visible={updateForm.visible}
        loading={updateForm.loading}
      >
        <AdminStihlProductSlideUpdateForm
          data={updateForm.data}
          onSuccess={onSuccessUpdate}
        />
      </Drawer>

    </>
  );
};

export default AdminStihlProductSlide;
