import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Spin, Divider, Switch, TimePicker, Upload, notification } from "antd";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";
import moment from "moment";

const timeFormat = "HH:mm";

const SiteSettingsUpdateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [socialSwitches, setSocialSwitches] = useState({});
  const [workingHoursSwitches, setWorkingHoursSwitches] = useState({});
  const [logoFileList, setLogoFileList] = useState([]);
  const [faviconFileList, setFaviconFileList] = useState([]);

  const [form] = Form.useForm();
  const { formRequest } = useApi();

  useEffect(() => {
    if (props.data) {
      if (props.data.weekdaysWorkingStartTime && props.data.weekdaysWorkingEndTime) {
        props.data.weekdaysWorkingTimes = [moment(props.data.weekdaysWorkingStartTime, timeFormat), moment(props.data.weekdaysWorkingEndTime, timeFormat)]
      }
      if (props.data.saturdayWorkingStartTime && props.data.saturdayWorkingEndTime) {
        props.data.saturdayWorkingTimes = [moment(props.data.saturdayWorkingStartTime, timeFormat), moment(props.data.saturdayWorkingEndTime, timeFormat)]
      }
      if (props.data.sundayWorkingStartTime && props.data.sundayWorkingEndTime) {
        props.data.sundayWorkingTimes = [moment(props.data.sundayWorkingStartTime, timeFormat), moment(props.data.sundayWorkingEndTime, timeFormat)]
      }

      setLogoFileList(props.data.siteLogo ? [{ uid: -1, status: 'done', url: props.data.siteLogo }] : []);
      setFaviconFileList(props.data.favicon ? [{ uid: -1, status: 'done', url: props.data.favicon }] : []);

      form.setFieldsValue(props.data);

      setSocialSwitches({
        showFacebook: props.data.showFacebook,
        showYouTube: props.data.showYouTube,
        showInstagram: props.data.showInstagram,
        showTwitter: props.data.showTwitter,
      });

      setWorkingHoursSwitches({
        isSaturdayWorking: props.data.isSaturdayWorking,
        isSundayWorking: props.data.isSundayWorking,
      });
    }
  }, [props.data]);

  const onFinish = async (values) => {
    if (values.weekdaysWorkingTimes) {
      const [startTime, endTime] = values.weekdaysWorkingTimes;
      values.weekdaysWorkingStartTime = startTime.format(timeFormat);
      values.weekdaysWorkingEndTime = endTime.format(timeFormat);
    }

    if (values.saturdayWorkingTimes) {
      const [startTime, endTime] = values.saturdayWorkingTimes;
      values.saturdayWorkingStartTime = startTime.format(timeFormat);
      values.saturdayWorkingEndTime = endTime.format(timeFormat);
    }

    if (values.sundayWorkingTimes) {
      const [startTime, endTime] = values.sundayWorkingTimes;
      values.sundayWorkingStartTime = startTime.format(timeFormat);
      values.sundayWorkingEndTime = endTime.format(timeFormat);
    }

    values.removeLogo = logoFileList.length === 0;
    values.removeFavicon = faviconFileList.length === 0;

    const response = await formRequest("/SiteSettings", "put", values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  const logoUploadProps = {
    fileList: logoFileList,
    maxCount: 1,
    accept: ".png,.jpg,.jpeg",
    listType: "picture-card",
    onRemove: (file) => {
      const index = logoFileList.indexOf(file);
      const newFileList = logoFileList.slice();
      newFileList.splice(index, 1);
      setLogoFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"]
      const isAllowed = allowedFileTypes.includes(file.type);

      if (!isAllowed) {
        notification.error({
          message: `'${file.name}' desteklenmeyen dosya tipinde.`,
          duration: 3,
        });
        return Upload.LIST_IGNORE;
      }

      setLogoFileList([...fileList]);
      return false;
    },
  };

  const faviconUploadProps = {
    fileList: faviconFileList,
    maxCount: 1,
    accept: ".ico",
    listType: "picture-card",
    onRemove: (file) => {
      const index = faviconFileList.indexOf(file);
      const newFileList = faviconFileList.slice();
      newFileList.splice(index, 1);
      setFaviconFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      const allowedFileTypes = ["image/x-icon"]
      const isAllowed = allowedFileTypes.includes(file.type);

      if (!isAllowed) {
        notification.error({
          message: `'${file.name}' desteklenmeyen dosya tipinde.`,
          duration: 3,
        });
        return Upload.LIST_IGNORE;
      }

      setFaviconFileList([...fileList]);
      return false;
    },
  };

  return (
    <Spin spinning={props.loading}>
      <Form
        {...defaultFormItemLayout}
        validateMessages={defaultValidateMessages}
        form={form}
        name="SiteSettingsUpdate"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={24}>

            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>

            <Divider>Genel</Divider>

            <Form.Item
              label="Site Başlığı"
              name="siteTitle"
              tooltip="Websitesinde bayi adı yerine kullanılır."
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Logo"
              name="siteLogo"
              getValueFromEvent={({ file }) => file}
            >
              <Upload {...logoUploadProps}><Button icon={<UploadOutlined />}>{logoFileList.length === 0 ? "Yükle" : "Değiştir"}</Button></Upload>
            </Form.Item>

            <Form.Item
              label="Favicon"
              name="favicon"
              getValueFromEvent={({ file }) => file}
            >
              <Upload {...faviconUploadProps}><Button icon={<UploadOutlined />}>{faviconFileList.length === 0 ? "Yükle" : "Değiştir"}</Button></Upload>
            </Form.Item>

            <Divider>Sosyal Medya</Divider>

            <Form.Item
              label="Facebook Görünür"
              name="showFacebook"
              valuePropName="checked"
            >
              <Switch onChange={(val) => setSocialSwitches({ ...socialSwitches, showFacebook: val })} />
            </Form.Item>
            <Form.Item
              label="Facebook Url"
              name="facebookUrl"
            >
              <Input disabled={!socialSwitches.showFacebook} />
            </Form.Item>

            <Form.Item
              label="YouTube Görünür"
              name="showYouTube"
              valuePropName="checked"
            >
              <Switch onChange={(val) => setSocialSwitches({ ...socialSwitches, showYouTube: val })} />
            </Form.Item>
            <Form.Item
              label="YouTube Url"
              name="youTubeUrl"
            >
              <Input disabled={!socialSwitches.showYouTube} />
            </Form.Item>

            <Form.Item
              label="Instagram Görünür"
              name="showInstagram"
              valuePropName="checked"
            >
              <Switch onChange={(val) => setSocialSwitches({ ...socialSwitches, showInstagram: val })} />
            </Form.Item>
            <Form.Item
              label="Instagram Url"
              name="instagramUrl"
            >
              <Input disabled={!socialSwitches.showInstagram} />
            </Form.Item>

            <Form.Item
              label="Twitter Görünür"
              name="showTwitter"
              valuePropName="checked"
            >
              <Switch onChange={(val) => setSocialSwitches({ ...socialSwitches, showTwitter: val })} />
            </Form.Item>
            <Form.Item
              label="Twitter Url"
              name="twitterUrl"
            >
              <Input disabled={!socialSwitches.showTwitter} />
            </Form.Item>

            <Divider>Çalışma Saatleri</Divider>

            <Form.Item
              label="Haftaiçi"
              name="weekdaysWorkingTimes"
              rules={[{ required: true }]}
            >
              <TimePicker.RangePicker
                format={timeFormat}
                minuteStep={5}
              />
            </Form.Item>

            <Form.Item
              label="Cumartesi Açık"
              name="isSaturdayWorking"
              valuePropName="checked"
            >
              <Switch onChange={(val) => setWorkingHoursSwitches({ ...workingHoursSwitches, isSaturdayWorking: val })} />
            </Form.Item>
            <Form.Item
              label="Cumartesi"
              name="saturdayWorkingTimes"
              rules={[{ required: workingHoursSwitches.isSaturdayWorking }]}
            >
              <TimePicker.RangePicker
                format={timeFormat}
                minuteStep={5}
                disabled={!workingHoursSwitches.isSaturdayWorking}
              />
            </Form.Item>

            <Form.Item
              label="Pazar Açık"
              name="isSundayWorking"
              valuePropName="checked"
            >
              <Switch onChange={(val) => setWorkingHoursSwitches({ ...workingHoursSwitches, isSundayWorking: val })} />
            </Form.Item>
            <Form.Item
              label="Pazar"
              name="sundayWorkingTimes"
              rules={[{ required: workingHoursSwitches.isSundayWorking }]}
            >
              <TimePicker.RangePicker
                format={timeFormat}
                minuteStep={5}
                disabled={!workingHoursSwitches.isSundayWorking}
              />
            </Form.Item>

            <Col style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
              >
                Güncelle
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
export default SiteSettingsUpdateForm;
