import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage("token", null);
  const [expiration, setExpiration] = useLocalStorage("expiration", null);
  const navigate = useNavigate();

  const login = async (data, returnUrl) => {
    setToken(data.token);
    setExpiration(data.expiration);

    navigate(returnUrl ?? "/", { replace: true });
  };

  const logout = () => {
    setToken(null);
    setExpiration(null);
    navigate("/signin", { replace: true });
  };

  const getClaims = () => {
    if (!token) return {};

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

    const claims = JSON.parse(jsonPayload);
    const roles = [].concat(claims["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]);
    return {
      id: claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
      name: claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
      surname: claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"],
      email: claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
      roles: roles,
      isSuperAdmin: roles.includes("Sadal.Admin")
    };
  };

  const value = useMemo(
    () => ({
      token,
      expiration,
      login,
      logout,
      claims: getClaims()
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
