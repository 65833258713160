import NotFound from "./ResultPages/NotFound";
import Unauthorized from "./ResultPages/Unauthorized";
import ChangePassword from "./Auth/ChangePassword";

import Tenant from "./Tenant";

import Home from "./Home";
import HomeSlide from "./Home/Slide";
import AdminHomeSlide from "./Home/AdminSlide";
import About from "./About";
import AboutGallery from "./About/Gallery";
import AboutReference from "./About/Reference";
import AboutDocument from "./About/Document";
import Brand from "./Brand";
import Service from "./Service";
import Product from "./Product";
import ProductCategory from "./Product/Category";
import Blog from "./Blog";
import BlogCategory from "./Blog/Category";
import Branch from "./Branch";
import SiteSettings from "./SiteSettings";
import AdminStihlProductSlide from "./Product/AdminStihlProductSlide";
import AdminSiteSettings from "./SiteSettings/AdminIndex";

const AppRoutes = (isSuperAdmin) => {
  if (isSuperAdmin) {
    return [
      {
        index: true,
        path: '/',
        element: <Tenant />
      },
      {
        path: '/home-settings',
        element: <Home />
      },
      {
        path: '/home-slide',
        element: <AdminHomeSlide />
      },
      {
        path: '/stihl-product',
        element: <AdminStihlProductSlide />
      },
      {
        path: '/admin-site-settings',
        element: <AdminSiteSettings />
      },

      {
        path: '/change-password',
        element: <ChangePassword />
      },
      {
        path: '/unauthorized',
        element: <Unauthorized />
      },
      {
        path: '*',
        element: <NotFound />
      },
    ];
  }

  return [
    {
      index: true,
      path: '/',
      element: <HomeSlide />
    },
    {
      path: '/about-settings',
      element: <About />
    },
    {
      path: '/about-gallery',
      element: <AboutGallery />
    },
    {
      path: '/about-reference',
      element: <AboutReference />
    },
    {
      path: '/about-document',
      element: <AboutDocument />
    },
    {
      path: '/brand',
      element: <Brand />
    },
    {
      path: '/service',
      element: <Service />
    },
    {
      path: '/product',
      element: <Product />
    },
    {
      path: '/product-category',
      element: <ProductCategory />
    },
    {
      path: '/blog-post',
      element: <Blog />
    },
    {
      path: '/blog-category',
      element: <BlogCategory />
    },
    {
      path: '/branch',
      element: <Branch />
    },
    {
      path: '/site-settings',
      element: <SiteSettings />
    },

    {
      path: '/change-password',
      element: <ChangePassword />
    },
    {
      path: '/unauthorized',
      element: <Unauthorized />
    },
    {
      path: '*',
      element: <NotFound />
    },
  ];
};

export default AppRoutes;
