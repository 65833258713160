import React from "react";
import { Menu } from "antd";
import {
  HomeOutlined,
  ShopOutlined,
  SettingOutlined,
  BranchesOutlined,
  SketchOutlined,
  InfoCircleOutlined,
  PictureOutlined,
  SolutionOutlined,
  FileOutlined,
  RobotOutlined,
  SlidersOutlined,
  ReadOutlined,
  GroupOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const getMenuItems = (isSuperAdmin) => {
  if (isSuperAdmin) {
    return [
      {
        path: '/',
        title: "Firmalar",
        icon: ShopOutlined,
      },
      {
        key: "home",
        title: "Ana Sayfa",
        icon: HomeOutlined,
        children: [
          {
            path: '/home-settings',
            title: "Ayarlar",
            icon: SettingOutlined,
          },
          {
            path: '/home-slide',
            title: "Slaytlar",
            icon: SlidersOutlined,
          },
        ]
      },
      {
        path: '/stihl-product',
        title: "Stihl Ürünleri",
        icon: ShopOutlined,
      },
      {
        path: '/admin-site-settings',
        title: "Site Ayarları",
        icon: SettingOutlined,
      },
    ];
  }

  return [

    {
      key: "home",
      title: "Ana Sayfa",
      icon: HomeOutlined,
      children: [
        {
          path: '/',
          title: "Slaytlar",
          icon: SlidersOutlined,
        },
      ]
    },
    {
      key: "about",
      title: "Hakkımızda",
      icon: InfoCircleOutlined,
      children: [
        {
          path: '/about-settings',
          title: "Ayarlar",
          icon: SettingOutlined,
        },
        {
          path: '/about-gallery',
          title: "Galeri",
          icon: PictureOutlined,
        },
        {
          path: '/about-reference',
          title: "Referanslar",
          icon: SolutionOutlined,
        },
        {
          path: '/about-document',
          title: "Dökümanlar",
          icon: FileOutlined,
        },
      ]
    },
    {
      path: '/brand',
      title: "Markalarımız",
      icon: SketchOutlined,
    },
    {
      path: '/service',
      title: "Hizmetlerimiz",
      icon: RobotOutlined,
    },
    {
      key: "products",
      title: "Ürünlerimiz",
      icon: ShopOutlined,
      children: [
        {
          path: '/product',
          title: "Ürünler",
          icon: ShoppingOutlined,
        },
        {
          path: '/product-category',
          title: "Kategoriler",
          icon: GroupOutlined,
        },
      ]
    },
    {
      key: "blog",
      title: "Blog / Haber",
      icon: ReadOutlined,
      children: [
        {
          path: '/blog-post',
          title: "Yazılar",
          icon: ReadOutlined,
        },
        {
          path: '/blog-category',
          title: "Kategoriler",
          icon: GroupOutlined,
        },
      ]
    },
    {
      path: '/branch',
      title: "Şubelerimiz",
      icon: BranchesOutlined,
    },
    {
      path: '/site-settings',
      title: "Site Ayarları",
      icon: SettingOutlined,
    },
  ];
}

const SideMenu = () => {
  const location = useLocation();
  const { claims } = useAuth();

  const getMenuItem = ({ key, path, title, icon, children }) => {
    return {
      key: key ?? (path === "/" ? "/" : path.slice(1)),
      label: path ? <Link to={path}>{title}</Link> : title,
      icon: icon ? React.createElement(icon) : undefined,
      children: Array.isArray(children) ? children.map(getMenuItem) : undefined,
    };
  }

  const selectedKeys = location.pathname === "/" ? "/" : location.pathname.slice(1);
  const defaultOpenKeys = selectedKeys.split("/").filter(x => x);

  return (
    <Menu
      className="site-menu"
      mode="inline"
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      items={getMenuItems(claims.isSuperAdmin).map(getMenuItem)}
    />
  );
};

export default SideMenu;
