import React from 'react'
import { Modal, Spin } from 'antd';

const CustomModal = ({ loading, children, ...rest }) => {

  return (
    <Modal
      width="100%"
      centered
      footer={null}
      {...rest}
    >
      <Spin spinning={loading === true}>
        {children}
      </Spin>
    </Modal>
  )
}

export default CustomModal;