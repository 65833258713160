import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; 
import tr from 'react-phone-input-2/lang/tr.json'

function CustomInputPhone(props) {
  return (
    <PhoneInput
      localization={tr}
      countryCodeEditable={false}
      country={"tr"}
      onlyCountries={["tr"]}
      inputClass="ant-input"
      inputStyle={{ width: "100%", borderRadius: "2px" }}
      {...props}
    />
  );
}

export default CustomInputPhone;
