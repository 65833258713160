import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button, Select, InputNumber, Upload, notification } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";

const BranchAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [dealerSelect, setDealerSelect] = useState({
    data: [],
    loading: false,
  });
  const [imageFileList, setImageFileList] = useState([]);
  const [form] = Form.useForm();
  const { getRequest, formRequest, getFilterQueryString } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onDealerSearch = async (val) => {
    if (val.length < 2) {
      return setDealerSelect({ data: [], loading: false });
    }

    const query = getFilterQueryString({
      select: ["id", "code", "name", "shortName", "address"],
      filter: [["shortName", "contains", val], "or", ["name", "contains", val], "or", ["code", "contains", val]]
    });
    setDealerSelect({ ...dealerSelect, loading: true });
    const response = await getRequest(`/Dealers${query}`);
    setDealerSelect({ data: response.isSuccess ? response.data.data : [], loading: false });
  };

  const onFinish = async (values) => {
    const response = await formRequest(`/Branches`, "post", values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      setImageFileList([]);
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  const imageUploadProps = {
    fileList: imageFileList,
    maxCount: 1,
    accept: ".png,.jpg,.jpeg",
    listType: "picture-card",
    onRemove: (file) => {
      const index = imageFileList.indexOf(file);
      const newFileList = imageFileList.slice();
      newFileList.splice(index, 1);
      setImageFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"]
      const isAllowed = allowedFileTypes.includes(file.type);

      if (!isAllowed) {
        notification.error({
          message: `'${file.name}' desteklenmeyen dosya tipinde.`,
          duration: 3,
        });
        return Upload.LIST_IGNORE;
      }

      setImageFileList([...fileList]);
      return false;
    },
  };

  return (
    <Form
      {...defaultFormItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="BranchAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item
            label="Bayi"
            name="dealerId"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Aramak için en az 2 harf giriniz.."
              onSearch={onDealerSearch}
              loading={dealerSelect.loading}
              filterOption={(input, option) => option.searchValue.toLocaleLowerCase("tr-TR").indexOf(input.toLocaleLowerCase("tr-TR")) >= 0}
            >
              {dealerSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id} searchValue={`${item.code}-${item.shortName}-${item.name}`}>
                  <>
                    <p><strong>{item.name}</strong></p>
                    <p>{`${item.code} - ${item.shortName}`}<br />{item.address}</p>
                  </>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Başlık"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Görsel"
            name="image"
            getValueFromEvent={({ file }) => file}
          >
            <Upload {...imageUploadProps}><Button icon={<UploadOutlined />}>{imageFileList.length === 0 ? "Yükle" : "Değiştir"}</Button></Upload>
          </Form.Item>

          <Form.Item
            label="Sıra"
            name="order"
            initialValue={0}
          >
            <InputNumber precision={0} />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default BranchAddForm;
