import React, { useState, useEffect } from 'react'
import { Card, Col, Empty, Row, Select } from 'antd';
import { useApi } from "../../hooks/useApi";
import AdminSiteSettingsUpdateForm from './AdminSiteSettingsUpdateForm';
import { PageHeader } from "../../components/AntdCustom";

const AdminSiteSettings = () => {
  const [updateForm, setUpdateForm] = useState({ data: {}, loading: false });
  const [tenantSelect, setTenantSelect] = useState({
    data: [],
    loading: true,
    activeId: null
  });

  const { getRequest, getFilterQueryString } = useApi();

  useEffect(() => {
    getTenants();
  }, []);

  useEffect(() => {
    if (!tenantSelect.activeId) return;

    getUpdateFormData();
  }, [tenantSelect.activeId])

  const getUpdateFormData = async () => {
    setUpdateForm({ ...updateForm, loading: true });
    const response = await getRequest(`/SiteSettings/AdminGetByTenant/${tenantSelect.activeId}`);

    if (!response.isSuccess) {
      return setUpdateForm({ ...updateForm, loading: false });
    }

    setUpdateForm({ data: response.data, loading: false });
  };

  const getTenants = async () => {
    const query = getFilterQueryString({
      select: ["id", "dealerCode", "dealerName", "dealerShortName", "dealerAddress", "siteTitle"],
      take: -1
    });
    const response = await getRequest(`/Tenants${query}`);

    if (!response.isSuccess) {
      return setTenantSelect({ ...tenantSelect, loading: false });
    }

    setTenantSelect({
      ...tenantSelect,
      data: response.data.data,
      loading: false,
    });
  };

  const onSuccessUpdate = () => {
    getUpdateFormData();
  };

  return (
    <>
      <PageHeader
        title="Site Ayarları"
        extra={
          [
            <Select
              showSearch
              loading={tenantSelect.loading}
              value={tenantSelect.activeId}
              onSelect={value => setTenantSelect({ ...tenantSelect, activeId: value })}
              dropdownMatchSelectWidth={400}
              style={{ width: 250 }}
              key={1}
              placeholder="Firma seçin"
              filterOption={(input, option) => option.searchValue.toLocaleLowerCase("tr-TR").indexOf(input.toLocaleLowerCase("tr-TR")) >= 0}
            >
              {tenantSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id} searchValue={`${item.dealerCode}-${item.dealerShortName}-${item.dealerName}-${item.siteTitle}`}>
                  <>
                    <p><strong>{item.dealerName}</strong></p>
                    <p>{`${item.dealerCode} - ${item.dealerShortName}`}<br />{item.dealerAddress}</p>
                  </>
                </Select.Option>
              ))}
            </Select>
          ]
        }
      />

      <Row className='gx-mt-3' justify='center'>
        <Col xs={24} lg={16}>
          <Card>

            {tenantSelect.activeId ? (<AdminSiteSettingsUpdateForm
              data={updateForm.data}
              loading={updateForm.loading}
              onSuccess={onSuccessUpdate}
            />) : (
              <Empty description="Düzenlemek için sağ üstten bir firma seçin." />
            )}

          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AdminSiteSettings;