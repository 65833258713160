import React, { useState, useEffect } from "react";
import { Input, Form, Col, Row, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { defaultFormItemLayout, defaultValidateMessages } from "../../utils/FormHelper";
import { useApi } from "../../hooks/useApi";

const DomainAddForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { postRequest } = useApi();

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const onFinish = async (values) => {
    const response = await postRequest(`/DomainAddresses`, values);

    setLoading(false);
    if (response.isSuccess) {
      form.resetFields();
      if (props.onSuccess) props.onSuccess();
    }
  };

  const onFinishFailed = () => setLoading(false);

  return (
    <Form
      {...defaultFormItemLayout}
      validateMessages={defaultValidateMessages}
      form={form}
      name="DomainAdd"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col span={24}>

          <Form.Item name="tenantId" hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Adres"
            name="address"
            rules={[{ required: true }]}
            tooltip={(
              <>
                <p>Alan adı eklerken gireceğiniz değer 'www.alanadi.com' şeklinde girilmelidir.</p>
                <p>www yazmadan da siteye erişilmesi isteniyor ise ayrıca 'alanadi.com' olarak yeni bir kayıt eklenmelidir.</p>
                <p>Yanlızca 'alanadi.com' şeklinde siteye erişilmesi isteniyor ise 'www.alanadi.com' şeklinde kayıt eklemeyin.</p>
              </>
            )}
          >
            <Input />
          </Form.Item>

          <Col style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Ekle
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};
export default DomainAddForm;
